(function($){

  var wbo = {

    init: function(){

      wbo.deprecatedCookies();
      wbo.detectParams();
      wbo.navs();
      wbo.triggers();
      wbo.submitInlineForm();

      if($('#anythingfilter').length){
        wbo.anythingfilter();
      }
      if($('.add-to-cart-button').length){
        wbo.checkPicks();
        wbo.addCart();
      }
      if($('.cart-count').length){
        wbo.countCartItems();
      }
      if($('.render-sidecart').length){
        wbo.showCart();
        wbo.hideCart();
        wbo.incrementCartQuantity();
        wbo.decrementCartQuantity();
      }
      if($('.small-product').length){
        wbo.bumpSouthernTobacco();
      }
      if($('.collect-shipping-information').length){
        wbo.selectShippingState();
        wbo.selectShippingCountry();
        // wbo.collectCartOrder();
        wbo.validShippingBeforeBilling();
      }
      if($('.cart-products.summary').length){
        wbo.selectShippingRate();
      }
      if($('#handle-account-form').length){
        wbo.handleMemberAccount();
      }
      if($('.pobox-detect').length){
        wbo.detectPOBox();
      }
      if($('.discount-chooser').length){
        wbo.setDiscountType();
        wbo.checkcoupon();
        wbo.couponRestoreRemove();
        wbo.discountApply();
      }
      if($('.location-map').length){
        wbo.locationsMap();
      }
      if($('.gift-card-use-toggle').length){
        wbo.giftCardCheck();
        wbo.submitGiftcardCheckout();
      }
      if($('.same-as-shipping-toggle').length){
        wbo.toggleBillingSameShipping();
      }
      if($('.price-variable').length){
        wbo.pickVariablePrice();
      }
      if($('select#birthmonth').length){
        wbo.enterBirthdate();
      }
      if($('.enter-address-prompt').length){
        wbo.enterMemberAddress();
        wbo.submitWireForm();
      }
      if($('.select-fill-trigger').length){
        wbo.setSelectField();
      }
      if($('.printable-styles').length){
        wbo.printableStyles();
      }
      if($('.global-lightbox-wrap').length){
        wbo.promoLightbox();
      }
      if($('.review-form-wrap').length){
        wbo.reviewForm();
      }
      if($('.product-multi-images').length){
        wbo.simpleGallerySetup();
        wbo.simpleGallery();
      }
    },
    deprecatedCookies: function(){
      // check cookie version
      var cart = Cookies.get('wbocart');
      if (typeof cart != 'undefined') {
        if(cart.includes('yyxx')){
          // convert cookie to json
          $.post('/updatecartformat?cart='+cart ,function(data){ });
        } else if (cart == ''){
          // deprecated
          Cookies.remove('wbocart');
          window.location.reload(true);
        }
      }
    },
    promoLightbox: function(){
      $('.global-lightbox-shade').on('click', function(){
        $('.global-lightbox-wrap').removeClass('active');
        var in2hours = 1/12;
        Cookies.set('wbo_lightbox_dismiss', 'yes', { expires: in2hours });
      });

    },
    // deprecated; can update and remove
    bumpSouthernTobacco: function(){

      $('.small-product[data-type="product"][data-scent="southern-tobacco"]').css('order', '-1');
      $('.small-product[data-type="product"][data-scent="dark-thirty"]').css('order', '-1');

    },
    navs: function(){

      $('.nav-trigger').on('click', function(){
        $('body').addClass('nav-open');
      });

      $('.nav-close').on('click', function(){
        $('body').removeClass('nav-open');
      });

    },
    detectParams: function(){

      var datatype = '';
      var param = '';
      let searchParams = new URLSearchParams(window.location.search);
      // check the url
      if(searchParams.has('scent')){
        datatype = 'scent';
        param = searchParams.get('scent');
      }
      if(searchParams.has('category')){
        datatype = 'category';
        param = searchParams.get('category');
      }
      if(searchParams.has('type')){
        datatype = 'type';
        param = searchParams.get('type');
      }
      if(searchParams.has('release')){
        datatype = 'release';
        param = searchParams.get('release');
      }
      // apply any filters
      if(datatype != ''){
        $('.small-product[data-' +datatype+ '="' +param+ '"]').addClass('filtered');
        $('body').addClass('using-filters');
      }
      // run initial
      wbo.countProducts();
      $('html').addClass('ready');
    },
    countProducts: function(){

      if($('body').hasClass('using-filters')){
        var tally = $('.small-product.filtered').length;
      } else {
        var tally = $('.small-product').length;
      }
      $('.showing-tally').html(tally);
      if(tally == 0){
        $('.product-grid-message').html('No products match the current filters.');
      }

    },
    anythingfilter: function() {
      var filterables = $('.filterables');
      $('input#anythingfilter').keyup(function(){
        var curr = $(this).val().toLowerCase();
        $('.filterable', filterables).each(function(){
          var thistext = $(this).text().toLowerCase();
          if(thistext.includes(curr)){
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      });
    },
    checkPicks: function(){

      $('select.check-picks').on('change', function(){
        // cycle and see if all four are selected
        var status = true;
        $('select.check-picks').each(function(){
          var thisvalue = $(this).val();
          if(thisvalue == null) {
            status = false;
          }
        });
        if(status == true){
          $('.add-to-cart-button.match-picks').addClass('all-picked');
        }
      });

    },
    addCart: function(){
    
      $('.add-to-cart-button').on('click', function(){
        var thisbutton = $(this);
        var product = thisbutton.closest('.product-details').data('no');
        var sku = thisbutton.closest('.price-sku-option').data('sku');
        var cart = Cookies.get('wbocart');
        var inventorydependent = true;

        if(thisbutton.hasClass('match-picks')){
          // make sku+scent+scent+scent+scent
          $('select.check-picks').each(function(){
            var thisvalue = $(this).val();
            sku += '#' + thisvalue;
          });
          inventorydependent = false;
          
        } else if(thisbutton.hasClass('variable')){
          var amount = $('input#amount').val();
          sku += amount;
        }

        $.ajax({
          url: '/additemtocart',
          type: 'POST',
          data: { 
                  product: product,
                  sku: sku, 
                  cart: cart,
                  inventory: inventorydependent
                },
          success: function(result){
            wbo.countCartItems();
            wbo.bounceCartCount();
          },
          error: function(result){ }
        });
        thisbutton.addClass('clicked');
        setTimeout(function(){
          thisbutton.removeClass('clicked');
        },600);
      });
    },
    incrementCartQuantity: function(){

      $('#sidecart').on('click', '.cart-sku-quantity-increment', function(){
        var product = $(this).closest('.cart-product').data('no');
        var sku = $(this).closest('.cart-sku').data('sku');
        $.ajax({
          url: '/incrementitemincart',
          type: 'POST',
          data: { 
                  product: product,
                  sku: sku
                },
          success: function(result){
            wbo.countCartItems();
            wbo.tallyCartTotal();
            wbo.tallyCartWeight();
          },
          error: function(result){ }
        });
      });

    },
    decrementCartQuantity: function(){

      $('#sidecart').on('click', '.cart-sku-quantity-decrement', function(){
        var product = $(this).closest('.cart-product').data('no');
        var sku = $(this).closest('.cart-sku').data('sku');
        $.ajax({
          url: '/decrementitemincart',
          type: 'POST',
          data: { 
                  product: product,
                  sku: sku
                },
          success: function(result){
            wbo.countCartItems();
            wbo.tallyCartTotal();
            wbo.tallyCartWeight();
          },
          error: function(result){ }
        });        
      });

    },
    countCartItems: function(){
      $.post('/countcartitems' ,function(data){ });
    },
    bounceCartCount: function(){
      $('.cart-wrap').addClass('show-update');
      setTimeout(function(){
        $('.cart-wrap').removeClass('show-update');
      },1000);
    },
    showCart: function(){
    
      $('.render-sidecart').on('click', function(){
        var cart = Cookies.get('wbocart');
        $.ajax({
          url: '/showcart',
          type: 'POST',
          data: { 
                  cart: cart
                },
          success: function(){
            // nothing for now
          }        
        });
      });
    
    },
    hideCart: function(){

      $('#sidecart').on('click', '.sidecart-close', function(){
        $('body').removeClass('sidecart-open');
      });
      $('#sidecart').on('click', '.sidecart-shade', function(){
        $('body').removeClass('sidecart-open');
      });

    },
    tallyCartTotal: function(){
      var tally = 0;
      $('#sidecart .cart-product').each(function(){
        var qtty = $('.cart-sku-quantity',this).html();
        var price = $('.cart-sku-price',this).html();
        var multiplied = +qtty * +price;
        tally = tally + multiplied;
      });
      $('.sidecart-tally').html(tally.toFixed(2));
    },
    tallyCartWeight: function(){

      var weight = 0;
      $('#sidecart .cart-product').each(function(){
        var qtty = $('.cart-sku-quantity',this).html();
        var add_weight = $('.cart-sku-weight',this).html();
        var multiplied = +qtty * +add_weight;
        weight = weight + multiplied;
      });
      $('.sidecart-weight').html(weight);
    },
    selectShippingCountry: function(){
      $('select[name="Country"]').on('change', function(){
        var country = $(this).val();
        if(country == 'United States (US)'){
          $('.collect-shipping-information').removeClass('intl');
          $('.cart-shipping').removeClass('use-intl');
          wbo.selectShippingState();
        } else {
          $('.collect-shipping-information').addClass('intl');
          $('.cart-shipping').addClass('use-intl');
          // add .selected to .cart-shipping-option based on cart weight
            // use data stored in tallyCartWeight();
          Cookies.remove('wboshipstate');
          wbo.selectShippingState();
        }
      });
    },
    selectShippingState: function(){
      // restore from previous
      var shipstate = Cookies.get('wboshipstate');
      if(shipstate != undefined){
        if(shipstate == 'Alabama'){
          $('.cart-products.summary .cart-tax-entry').data('tax-state', 'AL').data('tax-rate', '.1');
        } else {
          $('.cart-products.summary .cart-tax-entry').data('tax-state', '').data('tax-rate', '0');
        }
        $('select#state').val(shipstate);
      } else {
        $('.cart-products.summary .cart-tax-entry').data('tax-state', '').data('tax-rate', '0');
        $('select#state').val('Select State');       
      }
      wbo.tallySummaryCart();


      // listen for change
      $('select#state').on('change', function() {
        var shipstate = $(this).val();
        if(shipstate == 'Alabama'){
          $('.cart-products.summary .cart-tax-entry').data('tax-state', shipstate).data('tax-rate', '.1');
        } else {
          $('.cart-products.summary .cart-tax-entry').data('tax-state', shipstate).data('tax-rate', '0');
        }
        Cookies.set('wboshipstate', shipstate, { expires: 7 });
        wbo.tallySummaryCart();
      });

    },
    selectShippingRate: function(){
      // run on load
      // restore selection from cookie, if exists
      var shiptype = Cookies.get('wboship');
      if(shiptype != undefined){
        if($('.cart-shipping').hasClass('use-intl')){
          $('.cart-shipping-options.intl .cart-shipping-option[data-type="' +shiptype+ '"]').addClass('selected').siblings().removeClass('selected');
        } else {
          $('.cart-shipping-options.domestic .cart-shipping-option[data-type="' +shiptype+ '"]').addClass('selected').siblings().removeClass('selected');
        }
      }
      // recalculate total
      wbo.tallySummaryCart();

      // listen for change
      $('.cart-shipping-option').on('click', function(){
        var shiptype = $(this).data('type');
        
        $(this).addClass('selected').siblings().removeClass('selected');
        // then recalcuate total in .cart-products.summary
        wbo.tallySummaryCart();
        // then store in cookie
        Cookies.set('wboship', shiptype, { expires: 7 });
      });
      
      // check if UPS then no PO box
    },
    validateShippingFields: function(){
      // clear errors
      // $('.fieldgroup.required').removeClass('error');
      // check again
      var shipcountry = $('select[name="Country"]').val();

      $('.fieldgroup.required').each(function(){
        if($(this).hasClass('has-select')){
          var thisvalue = $('select',this).val();
          if(thisvalue == null && shipcountry == 'United States (US)'){
            $(this).addClass('error');
          } else {
            $(this).removeClass('error');
          }

        } else if($(this).hasClass('has-email')){
          var thisvalue = $('input',this).val();
          if(validateEmail(thisvalue)){
            $(this).removeClass('error');
            $('input[name="Notify"]').val(thisvalue);
          } else {
            $(this).addClass('error');
          }

        } else {
          var thisvalue = $('input',this).val();
          if(thisvalue != ''){
            $(this).removeClass('error');
          } else {
            $(this).addClass('error');
          }
        }
      });

      function validateEmail(email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
      }      
    },
    detectPOBox: function(){

      $('.fieldgroup.pobox-detect input[name="Street Address"]').keyup(function(){
        var address = $(this).val();
        if(address.toLowerCase().includes('box')) {
          $(this).closest('.fieldgroup').addClass('has-box');
        } else {
          $(this).closest('.fieldgroup').removeClass('has-box');
        }
      });

    },
    validShippingBeforeBilling: function(){

      $('.advance-to-billing').on('click', function(){
        wbo.validateShippingFields();
        if($('.fieldgroup.required.error').length){
          $('#valid-form-error-message').html('<p class="note">Please complete required fields.</p>');
        } else {
          wbo.copyShippingtoBillingFields();
          $('#valid-form-error-message').html('');
          $('.advance-to-billing').hide();
          if($('.gift-card').hasClass('apply-balance')){
            var rawcarttotal = $('.gift-card').data('origcartbalance');
            var appliedbalance = $('.gift-card').data('balance');
            if(+appliedbalance >= +rawcarttotal) {
              $('.no-addl-order').addClass('active');
              $('.billing-address').addClass('active');
            } else {
              $('.collect-billing-information').addClass('active');
              $('.billing-address').addClass('active');
            }
          } else {
            $('.collect-billing-information').addClass('active');
            $('.billing-address').addClass('active');
          }
        }
      });
    },
    toggleBillingSameShipping: function(){

      $('.same-as-shipping-toggle').on('click', function(){
        var billingState = $(this).closest('.billing-address');

        if($(billingState).hasClass('true')){
          billingState.removeClass('true');
        } else {
          billingState.addClass('true');
          wbo.copyShippingtoBillingFields();
        }
      });

    },
    copyShippingtoBillingFields: function(){

      var shipFirstName = $('input[name="First Name"]').val();
      var shipLasttName = $('input[name="Last Name"]').val();
      var shipCountry = $('select[name="Country"]').val();
      var shipStreetOne = $('input[name="Street Address"]').val();
      var shipStreetTwo = $('input[name="Street Address Two"]').val();
      var shipCity = $('input[name="City"]').val();
      var shipState = $('select[name="State"]').val();
      var shipZip = $('input[name="Zip"]').val();

      $('input[name="Billing First Name"]').val(shipFirstName);
      $('input[name="Billing Last Name"]').val(shipLasttName);
      $('select[name="Billing Country"]').val(shipCountry);
      $('input[name="Billing Street Address"]').val(shipStreetOne);
      $('input[name="Billing Street Address Two"]').val(shipStreetTwo);
      $('input[name="Billing City"]').val(shipCity);
      $('select[name="Billing State"]').val(shipState);
      $('input[name="Billing Zip"]').val(shipZip);

    },
    collectCartOrder: function(){

      $('form#shipbillform').on('submit', function(e){        
        if($('.fieldgroup.required.error').length){
          e.preventDefault();
          $('.checkout-action',this).append('<p class="note">Please complete required fields.</p>');
        } else {
          // deprecated; braintreee inline script submits form
        }
      });

    },
    tallySummaryCart: function(){
      var subtotal = 0;
      var all_weight = 0;
      var digitalonly = true; // change if even one is physical

      $('.cart-products.summary .cart-sku').each(function(){
        var qtty = $('.cart-sku-quantity',this).data('quantity');
        var price = $('.cart-sku-price',this).data('price');
        var weight = $('.cart-sku-weight',this).data('weight');
        var discountprice = $('.cart-sku-price',this).data('discountprice');
        if(+discountprice > 0){
          subtotal += (+discountprice * +qtty);
        } else {
          subtotal += (+price * +qtty);
        }
        if($(this).data('nature') == 'physical'){
          digitalonly = false;
        }

        all_weight = all_weight + (qtty * weight);
      });
      // console.log(all_weight);

      // set USPS First Class to zero if over $75, but don't force 'selected'
      // if digital only items in cart, set basic to free and force select
      if(subtotal >= 75){
        $('.cart-shipping-option[data-type="flat"]').data('value','0.00');
        $('.cart-shipping-option[data-type="flat"] .cart-shipping-option-price').html('<span class="strike">$4.99</span> FREE');
      } else if(digitalonly == true){
        $('.cart-shipping-option[data-type="flat"]').data('value','0.00').addClass('selected').siblings().removeClass('selected');
        $('.cart-shipping-option[data-type="flat"] .cart-shipping-option-price').html('<span class="strike">$4.99</span> Digital Delivery');
          Cookies.set('wboship', 'flat', { expires: 7 });
      } else {
        $('.cart-shipping-option[data-type="flat"]').data('value','4.99');
        $('.cart-shipping-option[data-type="flat"] .cart-shipping-option-price').html('$4.99');
      }

      // set international shipping
      if(all_weight < 16){
        $('.cart-shipping-option[data-type="international flat"]').addClass('selected').siblings().removeClass('selected');
      } else {
        $('.cart-shipping-option[data-type="international priority"]').addClass('selected').siblings().removeClass('selected');
      }
      
      if($('.cart-shipping').hasClass('use-intl')){
        var shipcost = $('.cart-shipping-options.intl .cart-shipping-option.selected').data('value');
      } else {
        var shipcost = $('.cart-shipping-options.domestic .cart-shipping-option.selected').data('value');
      }
      subtotal += +shipcost;

      // calcuate taxable amount
      var taxrate = $('.cart-tax-entry').data('tax-rate');
      // check for nontax items
      var nontax = 0.0;
      $('.cart-sku-price.nontax').each(function(){
        var thisprice = $(this).data('nontaxprice');
        nontax += +thisprice;
      });

      var taxamount = (subtotal - nontax) * +taxrate;
      subtotal += taxamount;
      $('.cart-tax-entry').html(taxamount.toFixed(2));


      if($('.gift-card').hasClass('apply-balance')){
        // set subtotal for billing add'l evaluation
        $('.gift-card').data('origcartbalance',subtotal);

        // set card no for submit
        var cardno = $('input#gift-card-number').val();
        $('input#giftcardforpay').val(cardno);

        // determine balance coverage
        var cardvalueraw = $('.gift-card').data('balance');
        var cardvalue = parseFloat(cardvalueraw);
        if(cardvalue >= subtotal) {
          cardamountapplied = subtotal;
          cardamountremain = cardvalue - cardamountapplied;
          subtotal = 0;
          $('.gift-card-message').html('<span class="positive">$' +cardamountapplied.toFixed(2)+ ' of your gift card balance will be applied to this order. Your remaining gift card balance will be&nbsp;$' +cardamountremain.toFixed(2)+ '.</span>');
        } else {
          subtotal -= cardvalue;  
          $('.gift-card-message').html('<span class="positive">Your remaining gift card balance of $' +cardvalue.toFixed(2)+ ' will be applied to this&nbsp;order.</span>');
        }
        // display x of y amount applied; z remaining on card || z to be paid
      }
      // check for .apply-balance
        // if present, get value
        // if value > subtotal, make subtotal $0.00

      $('.cart-products.summary .cart-total').html(subtotal.toFixed(2));

      // reset billing prompt, just in case
      $('.advance-to-billing').show();
      $('.no-addl-order').removeClass('active');
      $('.collect-billing-information').removeClass('active');      
      
    },
    submitGiftcardCheckout: function(){

      $('a.button.place-no-addl-order').on('click', function(){
        var thisform = $(this).closest('form');
        thisform.submit();
      });

    },
    handleMemberAccount: function(){

      $('.action-button').on('click', function(){
        var thisaction = $(this).data('action');
        if(thisaction == 'create-account'){
          var firstname = $('input[name="First Name"]').val();
          var lastname = $('input[name="Last Name"]').val();
          var email = $('input[name="Email"]').val();
          var hp = $('input[name="a_password"]').val();

            $.ajax({
              url: '/createcustaccount',
              type: 'POST',
              data: { 
                      firstname: firstname,
                      lastname: lastname,
                      email: email,
                      hp: hp
                    },
              success: function(result){
                
              },
              error: function(result){
                // console.log('error:');
                // console.log(result);
              }
            });
            
        } else if(thisaction == 'request-reset'){
          var email = $('input[name="Email"]').val();
          var hp = $('input[name="a_password"]').val();
            $.ajax({
              url: '/requestresetpassword',
              type: 'POST',
              data: { 
                      email: email,
                      hp: hp
                    },
              success: function(result){
                
              },
              error: function(result){
                // console.log('error:');
                // console.log(result);
              }
            });
                
        } else if(thisaction == 'reset-password'){
          // validate matching
          var pass = $('input[name="Password"]').val();
          var confirm = $('input[name="Password confirm"]').val();
          var email = $('input#email').val();
          var token = $('input#token').val();
          var hp = $('input[name="a_password"]').val();

          if(pass != confirm){
            $('#handle-account-form-message').html('<p class="error">The passwords fields do not match. Please try again.</p>');
          } else if(pass == ''){
            $('#handle-account-form-message').html('<p class="error">All fields are required.</p>');
          } else if(confirm == ''){
            $('#handle-account-form-message').html('<p class="error">All fields are required.</p>');
          } else {
               $.ajax({
                 url: '/processresetpassword',
                 type: 'POST',
                 data: { 
                         email: email,
                         pass: pass,
                         confirm: confirm,
                         token: token,
                         hp: hp
                       },
                 success: function(result){
                   
                 },
                 error: function(result){
                   // console.log('error:');
                   // console.log(result);
                 }
               });
          }
        }
      });
    },
    setDiscountType: function(){
      // restore on page load
      var discounttype = Cookies.get('wbodiscounttype');
      if(discounttype != undefined){
        $('.discount-type[data-type="' +discounttype+ '"]').addClass('selected').siblings().removeClass('selected');
        $('.discount-type-validate[data-type="' +discounttype+ '"]').addClass('selected').siblings().removeClass('selected');
      } else {

      }

      // listen for changes
      $('.discount-type').on('click', function(){
        var type = $(this).data('type');
        Cookies.set('wbodiscounttype', type);
        $(this).addClass('selected').siblings().removeClass('selected');
        $('.discount-type-validate[data-type="' +type+ '"]').addClass('selected').siblings().removeClass('selected');
        // run discountApply, which validates and updates prices
          // will also restore original html if 'None'
        wbo.discountApply();
      });

    },
    checkcoupon: function(){
    
      $('#checkout-coupon').on('click', '.submit-coupon', function(){
        var code = $('input#couponcode').val();
        if (code != ''){
          $.ajax({
            url: '/checkcoupon',
            type: 'POST',
            data: { 
                    code: code
                  },
            success: function(result){
              wbo.discountApply();
            },
            error: function(result){
            }
          });
        } else {
         $('.checkout-coupon-message').html('<p class="error">Please enter a coupon code.</p>');
        }
      });    
    },
    couponRestoreRemove: function(){

      // restore from cookie
      var discounttype = Cookies.get('wbodiscounttype');
      if(discounttype == undefined){

      } else if(discounttype == 'coupon'){
        // validate coupon
        // ajax replace message
        var code = Cookies.get('wbodiscountcode');
        if(code != undefined){
          $.post('/checkcoupon?code='+code ,function(data){ });
        }
      } else {

      }      

      // remove
      $('.discount-chooser').on('click', '.remove-coupon', function(){
        $('.checkout-coupon-message').html('<p class="success">Removed coupon.</p>');
        $('.checkout-coupon').removeClass('applied');
        Cookies.remove('wbodiscountcode');
        wbo.discountApply();
      });

    },
    discountApply: function(){
      // check for discount type
      // apply to cart based on type, iterate over products

      var discounttype = Cookies.get('wbodiscounttype');
      // console.log(discounttype);
      if(discounttype == undefined){

      } else if(discounttype == 'sale'){
        // get criteria & value from ajax
        // apply to each matching cart product
      } else if(discounttype == 'coupon'){
        // get value from ajax
        // apply to each cart product
        var code = Cookies.get('wbodiscountcode');
        if(code != undefined){
          $.ajax({
            url: '/getcouponvalue',
            type: 'POST',
            dataType: 'json',
            data: { code: code },
            success: function(result){
              // console.log(result);
              $('.checkout-coupon-message').html(result.message +'<p class="success">Applied code ' +result.code+ ' for  ' +(result.value * 100)+ '% off.<br><span class="remove-coupon">Remove?</span></p>');
              $('.checkout-coupon').addClass('applied');
              $('input#couponcode').val('');
              applyCoupon(result.value);
              wbo.tallySummaryCart();
            },
            error: function(result){ }
          });
        } else {
          restoreOriginal();
          wbo.tallySummaryCart();
        }

      } else if(discounttype == 'militaryleo'){
        $.ajax({
          url: '/cartcheckmilitaryleo',
          type: 'POST',
          dataType: 'json',
          data: { code: code },
          success: function(result){
            // console.log(result);
            $('.checkout-militaryleo-message').html('<p class="success">' + result.message + '</p>');
            if(result.value > 0){
              applyCoupon(result.value);
              wbo.tallySummaryCart();
            } else {
              restoreOriginal();
            }
          },
          error: function(result){ }
        });

      } else { // assumes 'None'
        restoreOriginal();
      }


      // reset original price html
      function restoreOriginal(){
        $('.cart-sku .cart-sku-price').each(function(){
          var originalprice = $(this).data('price');
          $(this).html('$' + originalprice);
          $(this).data('discountprice', '');
        });        
      }

      // apply coupon percentage discount
      function applyCoupon(discount){
        $('.cart-sku .cart-sku-price').each(function(){
          var currentvalue = $(this).data('price');
          var newprice = +currentvalue - (+currentvalue * +discount);
          // change html; change data-price
          $(this).html('<span class="strike">$' + currentvalue + '</span> $' + newprice.toFixed(2));
          $(this).data('discountprice', newprice);
        });
      }
    },
    renderLocationsForMap: function(){

      // retrieve zip and radius, then pass to app for locations
      var zip = $('.zip-entry input#zip').val();
      var radius = $('.zip-entry input#radius').val();

      if(zip > 0 && zip < 100000){
        // console.log('valid:' + zip);
        $.post('/findlocations?zip='+zip +'&radius='+radius ,function(data){ });

        Cookies.set('wbolocationzip',zip);
        Cookies.set('wbolocationradius',radius);
      }

    },
    locationsMap: function(){
      // run on load
      wbo.renderLocationsForMap();
      setTimeout(function(){
        // wait for retrieve locations then render map
        $('.location-map').each(function(){
            var map = initMap( $(this) );
        });
      },1000);

      // then listen for updates
      $('.zip-update').on('click', function(){
        wbo.renderLocationsForMap();
        setTimeout(function(){
          // wait for retrieve locations then render map
          $('.location-map').each(function(){
              var map = initMap( $(this) );
          });
        },1000);
      });

      // Map setup
      function initMap( $el ) {

          // Find marker elements within map.
          var $markers = $el.find('.marker');

          // Create gerenic map.
          var mapArgs = {
              zoom        : $el.data('zoom') || 16,
              mapTypeId   : google.maps.MapTypeId.ROADMAP
          };
          var map = new google.maps.Map( $el[0], mapArgs );

          // Add markers.
          map.markers = [];
          $markers.each(function(){
              initMarker( $(this), map );
          });

          // Center map based on markers.
          centerMap( map );

          // Return map instance.
          return map;
      }

      // Marker(s)
      function initMarker( $marker, map ) {

          // Get position from marker.
          var lat = $marker.data('lat');
          var lng = $marker.data('lng');
          var latLng = {
              lat: parseFloat( lat ),
              lng: parseFloat( lng )
          };

          // Create marker instance.
          var marker = new google.maps.Marker({
              position : latLng,
              map: map
          });

          // Append to reference for later use.
          map.markers.push( marker );

          // If marker contains HTML, add it to an infoWindow.
          if( $marker.html() ){

              // Create info window.
              var infowindow = new google.maps.InfoWindow({
                  content: $marker.html()
              });

              // Show info window when marker is clicked.
              google.maps.event.addListener(marker, 'click', function() {
                  infowindow.open( map, marker );
              });
          }
      }

      // Center (and Zoom)
      function centerMap( map ) {

          // Create map boundaries from all map markers.
          var bounds = new google.maps.LatLngBounds();
          map.markers.forEach(function( marker ){
              bounds.extend({
                  lat: marker.position.lat(),
                  lng: marker.position.lng()
              });
          });

          // Case: Single marker.
          if( map.markers.length == 1 ){
              map.setCenter( bounds.getCenter() );

          // Case: Multiple markers.
          } else{
              map.fitBounds( bounds );
          }
      }


    },
    giftCardCheck: function(){
      $('.gift-card-use-toggle').on('click', function(){
        var thisuse = $(this).closest('.gift-card');
        if($(thisuse).hasClass('in-use')){
          thisuse.removeClass('in-use apply-balance');
          $('input',thisuse).val('');
          $('.gift-card-message').html('');
          wbo.tallySummaryCart();

        } else {
          thisuse.addClass('in-use');
        }
      });

      // listen for .gift-card-use-toggle
        // if closed then open, otherwise reset apply state and recalculate total
      
      $('.gift-card-check').on('click', function(){
        var thiscardno = $('input#gift-card-number').val();
        if(thiscardno != ''){
          $(this).closest('.gift-card-validate').addClass('checking-card');
          $.post('/checkgiftcard?no='+thiscardno ,function(data){ });
          // callback/promise instead?
          setTimeout(function(){
            wbo.tallySummaryCart();
          },500);
        }
      });

      // listen for .gift-card-check
        // retrieve card number and ajax check
        // ajax will add value to .gift-card.apply-balance data-value=""
    
    },
    pickVariablePrice: function(){

      // listen for change, reset if not all numbers
      $('.price-variable input#amount').on('keyup', function(){
        var amount = $(this).val();
        if(isNaN(amount)){
          alert('Please enter only numbers.');
          $(this).val('');
        } else {

        }
      });

    },
    enterBirthdate: function(){

      $('button#birthdate').on('click', function(){
        var thismonth = $('select#birthmonth').val();
        var thisday = $('select#birthday').val();
        $.post('/addbirthdate?month='+thismonth +'&day='+thisday,function(data){ });
      });

    },
    enterMemberAddress: function(){

      $('#enter-address-wrap').on('click', '.enter-address-prompt', function(){
        $.post('/loadmemberaddress',function(data){ });
      });

    },
    setSelectField: function(){

      $('.set-select.listen').each(function(){
        var thisfield = $(this).data('field');
        var thisvalue = $(this).data('value');

        $(this).next('select[name="' +thisfield+ '"]').val(thisvalue);
        $(this).removeClass('listen');
      });

    },
    submitInlineForm: function(){

      $('.core').on('submit', 'form.dynamic-form', function(e){
        e.preventDefault();
      });

      $('.core').on('click', '.dynamic-send-button', function(e){
        // check required fields
        var no_errors = check_required();

        if(no_errors == true ) {
          // collect form and submit
          var thisform = $(this).closest('form.dynamic-form');
          var destination = thisform.data('form');
          $.ajax({
            url: destination,
            type: 'POST',
            data: thisform.serialize()
          });        
        }

      });

      function check_required() {
        var clean = true;
        // check inputs
        $('.fieldgroup.required input').each(function(){
          var val = $(this).val();
          if((val.trim().length === 0)) {
            clean = false;
            $(this).closest('.fieldgroup.required').addClass('error');
          } else {
            $(this).closest('.fieldgroup.required').removeClass('error');
          }
        });
        // check textareas
        $('.fieldgroup.required textarea').each(function(){
          var val = $('textarea').val();
          if((val.trim().length === 0)) {
            clean = false;
            $(this).closest('.fieldgroup.required').addClass('error');
          } else {
            $(this).closest('.fieldgroup.required').removeClass('error');
          }
        });

        return clean;
      }

    },
    submitWireForm: function(){

      $('.core').on('click', '.save-button', function(e){
        e.stopPropagation();
        var thisform = $(this).closest('form.a-wire');
        var destination = thisform.data('form');
        $.ajax({
          url: destination,
          type: 'POST',
          data: thisform.serialize()
        });
      });

      $('.core').on('click', '.cancel-button', function(e){
        e.stopPropagation();
        var destination = $(this).closest('form').data('form');
        $.post('/cancel'+destination,function(data){ });
      });

    },
    reviewForm: function(){

      $('.review-form-trigger').on('click', function(){
        var product = $('.product-details').data('no');
        $.post('/review-form-load?no='+product,function(data){ });
      });

    },
    simpleGallerySetup: function(){

      $('.product-multi-images').each(function(){
        var firstimg = $('.product-multi-image:first',this);
        // var getsrc = $('img',firstimg).data('url');
        // $('img',firstimg).attr('src', getsrc);
        firstimg.addClass('active');

        // count number of images
        // render same number of dots
        var imgcount = $('.product-multi-image',this).length;
        for (let i = 0; i < imgcount; i++) {
          $('.product-images-dots',this).append('<span class="product-images-dot"></span>');
        }

        // activate first dot
        $('.product-images-dot:first',this).addClass('active');

        // add arrows
        if (imgcount > 1) {
          $('.product-images-dots',this).prepend('<span class="product-images-dot-prev"></span>').append('<span class="product-images-dot-next"></span>');
        }
      });

    },
    simpleGallery: function(){

      $('.product-images-dots').on('click', '.product-images-dot', function(){
        var thisdotpos = $(this).index();
          // console.log(thisdotpos);
        var thisgroup = $(this).closest('.product-multi-images');
        var thisimage = $('.product-multi-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
        // load the image
        var getsrc = $('img',thisimage).data('url');
        $('img',thisimage).attr('src', getsrc);
        // indicate active
        $(this).addClass('active').siblings().removeClass('active');
        thisimage.addClass('active').siblings().removeClass('active');
      });

      $('.product-images-dots').on('click', '.product-images-dot-next', function(){
        var thisset = $(this).closest('.product-images-dots');
        var pos = $('.product-images-dot.active',thisset).index();
        var len = $('span', thisset).length;
        // console.log(len);
        // console.log(pos);

        if(pos < (len - 2)){
          var thisdot = $('.product-images-dot.active',thisset).next();
          var thisdotpos = pos + 1;
          var thisgroup = $(this).closest('.product-multi-images');
          var thisimage = $('.product-multi-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
          // load the image
          var getsrc = $('img',thisimage).data('url');
          $('img',thisimage).attr('src', getsrc);
          // indicate active
          thisdot.addClass('active').siblings().removeClass('active');
          thisimage.addClass('active').siblings().removeClass('active');
        }
      });

      $('.product-images-dots').on('click', '.product-images-dot-prev', function(){
        var thisset = $(this).closest('.product-images-dots');
        var pos = $('.product-images-dot.active',thisset).index();
        var len = $('span', thisset).length;
        // console.log(len);
        // console.log(pos);

        if(pos > (1)){
          var thisdot = $('.product-images-dot.active',thisset).prev();
          var thisdotpos = pos - 1;
          var thisgroup = $(this).closest('.product-multi-images');
          var thisimage = $('.product-multi-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
          // load the image
          var getsrc = $('img',thisimage).data('url');
          $('img',thisimage).attr('src', getsrc);
          // indicate active
          thisdot.addClass('active').siblings().removeClass('active');
          thisimage.addClass('active').siblings().removeClass('active');
        }
      });


      // listen for dot clicks
      // get index position; make active, siblings inactive

    },
    printableStyles: function(){
      $('body').addClass('printy');
    },
    triggers: function(){
      $('.sidecart-tally').on('click', function(){
        wbo.tallyCartTotal();
        wbo.tallyCartWeight();
      });
      $('.select-fill-trigger').on('click', function(){
        wbo.setSelectField();
      });
    }

  };


  $(window).on('load', function(){

    wbo.init();

  });

  $(window).on('resize', function() {

  });


})(window.jQuery);